import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, call } from "redux-saga/effects";
import { getMall, getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetMallDetails: "[Set MallDetails] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  mallDetails: undefined
};

export const reducer = persistReducer(
  {
    storage, key: "anc-auth", whitelist: ["authToken", "user"
    ]
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { userData } = action.payload;
        return { authToken: userData.token, user: { ...userData } };
      }

      case actionTypes.Register: {
        const { token } = action.payload;

        return { authToken: token, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        // const { user } = action.payload;
        return { ...state };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetMallDetails: {
        const { mallDetails } = action.payload;
        return { ...state, mallDetails: { ...mallDetails } };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (userData) => ({ type: actionTypes.Login, payload: { userData } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setMallDetails: (mallDetails) => ({ type: actionTypes.SetMallDetails, payload: { mallDetails } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    // yield put(actions.requestUser());
    // yield put(actions.fulfillUser());
    const mallDetails = yield call(getMall);
    yield put(actions.setMallDetails(mallDetails.data.response[0]));
    // console.log(state)
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
