export const downloadImage = (imageFile, name) => {
  if (imageFile) {
    var element = document.createElement("a");
    // var file = new Blob(
    //   [
    //     "https://timesofindia.indiatimes.com/thumb/msid-70238371,imgsize-89579,width-400,resizemode-4/70238371.jpg"
    //   ],
    //   { type: "image/*" }
    // );
    element.href = imageFile;
    element.target = "_blank";
    element.download = `${name ? name : "image"}.jpg`;
    element.title = '${name ? name : "image"}';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
};

export const getSrc = (url) => {
  return `${process.env.REACT_APP_IMAGE_BASE_URL}${url}`;
};
