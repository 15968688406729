import http from "../../../../http";
import axios from "axios";
const instance = axios.create();

export const LOGIN_URL = `/auth/login`;
export const REGISTER_URL = "/auth/register";
export const REQUEST_PASSWORD_URL = "/auth/forgot-password";
export const ME_URL = `/auth/me`;
export const MALL_URL = '/mall/get/all';

export function login(email, password) {
  return http.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return http.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return http.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return http.get(ME_URL);
}

export async function getMall() {
  const url = `${process.env.REACT_APP_API_URL}${MALL_URL}`;
  return await instance.get(url);
}