import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import Dashboard from "./modules/Dashboard";

// const UserProfilepage = lazy(() =>
//   import("./modules/UserProfile/UserProfilePage")
// );

const Campaign = lazy(() => import("./modules/Campaign/pages/index"));
const Category = lazy(() => import("./modules/Categories/pages/index"));
const Place = lazy(() => import("./modules/Place/pages/index"));
const Service = lazy(() => import("./modules/Services/pages/index"));
const Settings = lazy(() => import("./modules/Settings/pages/index"));
const MallManagement = lazy(() =>
  import("./modules/MallManagement/pages/index")
);

export default function BasePage() {
  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const mallId = useSelector((state) => state.auth.mallDetails?._id);

  if (!mallId) return <div>Loading...</div>;

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={Dashboard} />
        <Route path="/campaign" component={Campaign} />
        <Route path="/categories" component={Category} />
        <Route path="/places" component={Place} />
        <Route path="/services" component={Service} />
        <Route path="/mallmanagement" component={MallManagement} />
        <Route path="/settings" component={Settings} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
