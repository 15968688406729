import React, { useState, useEffect } from "react";
import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { getDashboardData } from "./crud";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";

const CampignCard = ({
  total = "N.A.",
  activeCount = "N.A.",
  expiredCount = "N.A.",
}) => {
  return (
    <div className="card custom-card col-lg-3 m-5" style={styles.countCard}>
      <h2 className="pb-5 mb-4">Campaign Overview</h2>
      <div className="py-2">
        <h5 className="p-0 m-0 mb-3 text-capitalize">Total Campaigns</h5>
        <h2>{total}</h2>
      </div>
      <div className="py-2">
        <h5 className="p-0 m-0 mb-3 text-capitalize">Active</h5>
        <h2>{activeCount}</h2>
      </div>
      <div className="py-2">
        <h5 className="p-0 m-0 mb-3 text-capitalize">Expired</h5>
        <h2>{expiredCount}</h2>
      </div>
    </div>
  );
};

const CategoryCard = ({
  categoryCount = "N.A.",
  subCategoryCount = "N.A.",
  brandsCount = "N.A.",
}) => {
  return (
    <div className="card custom-card col-lg-3 m-5" style={styles.countCard}>
      <h2 className="pb-5 mb-4">Category Overview</h2>
      <div className="py-2">
        <h5 className="p-0 m-0 mb-3 text-capitalize">Total Category</h5>
        <h2>{categoryCount}</h2>
      </div>
      <div className="py-2">
        <h5 className="p-0 m-0 mb-3 text-capitalize">Total Sub Category</h5>
        <h2>{subCategoryCount}</h2>
      </div>
      <div className="py-2">
        <h5 className="p-0 m-0 mb-3 text-capitalize">Total Brands</h5>
        <h2>{brandsCount}</h2>
      </div>
    </div>
  );
};

const DownloadsCard = ({
  total = "N.A.",
  androidCount = "N.A.",
  iosCount = "N.A.",
}) => {
  return (
    <div className="card custom-card col-lg-3 m-5" style={styles.countCard}>
      <h2 className="pb-5 mb-4">App Usage Overview</h2>
      <div className="py-2">
        <h5 className="p-0 m-0 mb-3 text-capitalize">Total Downloads</h5>
        <h2>{total}</h2>
      </div>
      <div className="py-2">
        <h5 className="p-0 m-0 mb-3 text-capitalize">Android Downloads</h5>
        <h2>{androidCount}</h2>
      </div>
      <div className="py-2">
        <h5 className="p-0 m-0 mb-3 text-capitalize">iOS Downloads</h5>
        <h2>{iosCount}</h2>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const mallId = useSelector((state) => state.auth.mallDetails?._id);

  useEffect(() => {
    setIsLoading(true);
    getDashboardData(mallId).then((data) => {
      setData(data);
      setIsLoading(false);
    });
  }, [mallId]);

  return (
    <>
      {isLoading && <ModalProgressBar />}
      <div className="container">
        <div className="row">
          <DownloadsCard androidCount={data?.usersAndroid} iosCount={data?.usersIos} total={data?.users}/>
          <CampignCard total={data?.totalCampaigns} activeCount={data?.activeCampaigns} expiredCount={data?.inActiveCampaigns}/>
          <CategoryCard categoryCount={data?.categories} subCategoryCount={data?.subCategories} brandsCount={data?.places}/>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

const styles = {
  countCard: {
    padding: "1.25rem",
    paddingRight: "4rem",
  },
};
