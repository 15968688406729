import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { campaignsSlice } from "../app/modules/Campaign/_redux/campaigns/campaignsSlice";
import { CategorySlice } from "../app/modules/Categories/_redux/categories/slice";
import { SubCategorySlice } from "../app/modules/Categories/_redux/sub-categories/slice";
import { PlacesSlice } from "../app/modules/Place/_redux/places/slice";
import { ServicesSlice } from "../app/modules/Services/_redux/services/slice";

import * as auth from "../app/modules/Auth/_redux/authRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  campaigns: campaignsSlice.reducer,
  categories: CategorySlice.reducer,
  subCategories: SubCategorySlice.reducer,
  places: PlacesSlice.reducer,
  services: ServicesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
