import axios from "axios";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";

const showSuccessToast = (msg) => {
  msg && toast.success(msg);
};

const showErrorToast = (msg) => {
  msg && toast.error(msg);
};

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const history = createBrowserHistory();

// For GET requests
http.interceptors.request.use(
  (req) => {
    // Add configurations here
    req.headers.Authorization = "";
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// For POST requests
http.interceptors.response.use(
  (res) => {
    // Add configurations here
    if (res.status === 201) {
    }
    showSuccessToast(res.data?.message);
    return res.data?.response;
  },
  (err) => {
    showErrorToast(err.response?.data?.message)
    return Promise.reject(err);
  }
);

export default http;
